import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productList", "arrow", "removeButton"]

  connect() {
    console.log("Product controller connected")
    this.initializeArrows()
  }

  initializeArrows() {
    console.log('Initializing arrows')
    this.arrowTargets.forEach(arrow => {
      arrow.addEventListener('click', () => {
        console.log('Arrow clicked')
        arrow.querySelector('i').classList.toggle('fa-rotate-90')
      })
    })
  }

  removeProduct(event) {
    event.preventDefault()
    const button = event.currentTarget
    const productId = button.dataset.productId
    const confirmMessage = "Are you sure you want to remove this product from your list?"

    if (confirm(confirmMessage)) {
      fetch(button.form.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: new FormData(button.form)
      }).then(response => {
        if (response.ok) {
          // Remove the <li> element
          const listItem = document.getElementById(`product-${productId}`)
          if (listItem) {
            listItem.remove()
          }

          // Check if the product list is empty
          if (this.productListTarget.children.length === 0) {
            // If empty, refresh the page
            window.location.reload()
          } else {
            // Optionally, you can update other parts of the page here
            // For example, update a counter or refresh a specific section
          }
        } else {
          console.error('Failed to remove product')
        }
      }).catch(error => {
        console.error('Error:', error)
      })
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}